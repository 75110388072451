import React from "react";
import styled from "styled-components";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { parseDomain, ParseResultType } from "parse-domain";

const { publicRuntimeConfig } = getConfig();

const StyledEntitiesHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  padding-left: 15px;
  padding-right: 15px;

  .header-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    img {
      width: 100%;
    }
  }

  .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 2rem;
    margin-bottom: 2rem;

    width: 950px;

    z-index: 1;

    .header-title {
      font-family: 'MerriweatherRegular';
      font-size: 34px;
      line-height: calc(1.42857rem * (34 / 16));
      text-align: center;
      color: #424242;
    }

    .header-text {
      font-family: 'MerriweatherLight';
      font-size: 20px;
      line-height: calc(1.42857rem * (20 / 16));
      text-align: center;
      color: #424242;
    }
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    .header-content {
      width: 720px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    .header-content {
      width: 100%;

      .header-title {
        margin-bottom: 16px;
        font-size: 28px;
        line-height: calc(1.42857rem * (28 / 16));
      }

      .header-text {
        font-size: 16px;
        line-height: calc(1.42857rem * (16 / 16));
      }
    }
  }
`;

function EntitiesHeaderSection() {
  const router = useRouter();
  let domainName = "";
  const parseResult = parseDomain(typeof window !== 'undefined' && window.location.hostname ? window.location.hostname : '');
  if (parseResult.type === ParseResultType.Listed) {
    const { domain } = parseResult;
    domainName = domain;
  }
  return (
    <StyledEntitiesHeaderSection>
      <div className="header-background">
          <img
            src={`${publicRuntimeConfig.basePath}/images/V2/V2-reflection-2/ein-tax-filing-american-flag-header.png`}
            alt=""
            layout="fill"
          />
        </div>
      {domainName!=="us-taxfiling" && process.env.NEXT_PUBLIC_BASE_PATH !== "/2" && process.env.NEXT_PUBLIC_BASE_PATH !== "/3" && process.env.NEXT_PUBLIC_BASE_PATH !== "/4" && process.env.NEXT_PUBLIC_BASE_PATH !== "/5" && !router.asPath.includes("EINLLC2") &&
        <div className="header-content">
          <div className="header-title">
          <span>
            Apply For IRS EIN / Federal Tax ID Number
          </span>
          </div>
          <div className="header-text">
          <span>
            Start Your Application By Selecting An Entity Type:
          </span>
          </div>
        </div>
      }
      {domainName === "us-taxfiling" &&
        <div className="header-content">
          <div className="header-title">
          <span>
            Apply For EIN / Federal Tax ID Number
          </span>
          </div>
          <div className="header-text">
          <span>
            Start Your Application By Selecting An Entity Type:
          </span>
          </div>
        </div>
      }
        {router.asPath.includes("EINLLC2") &&
        <div className="header-content">
        <div className="header-text">
          <span>
            Begin the Process by Choosing Your Business Type Below
          </span>
        </div>
      </div>}
      {process.env.NEXT_PUBLIC_BASE_PATH === "/2" && <div className="header-content">
          <div className="header-title">
          <span>
            Start Your Online E-File Return Today
          </span>
          </div>
          <div className="header-text">
          <span>
            Start Tax Application: Select Entity Type Below:
          </span>
          </div>
        </div>
      }
      {process.env.NEXT_PUBLIC_BASE_PATH === "/3" && <div className="header-content">
        <div className="header-title">
          <span>
            File Your Online E-Filing ID Today
          </span>
        </div>
        <div className="header-text">
          <span>
            Begin Tax Application: Select Type (Entity) Below:
          </span>
        </div>
      </div>
      }
      {process.env.NEXT_PUBLIC_BASE_PATH === "/4" && <div className="header-content">
        <div className="header-title">
          <span>
            Register Your Online E-Filing ID Now
          </span>
        </div>
        <div className="header-text">
          <span>
            Start Your Tax Application: Choose Your Entity Type Below:
          </span>
        </div>
      </div>
      }
      {process.env.NEXT_PUBLIC_BASE_PATH === "/5" && <div className="header-content">
        <div className="header-title">
          <span>
            Simple Federal EIN/Tax ID Form
          </span>
        </div>
      </div>
      }
    </StyledEntitiesHeaderSection>
  );
}

export default EntitiesHeaderSection;
